(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("dayjs"), require("dayjs_plugin_advancedFormat"), require("dayjs_plugin_customParseFormat"), require("dayjs_plugin_localeData"), require("dayjs_plugin_weekOfYear"), require("dayjs_plugin_weekYear"), require("dayjs_plugin_weekday"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "dayjs", "dayjs_plugin_advancedFormat", "dayjs_plugin_customParseFormat", "dayjs_plugin_localeData", "dayjs_plugin_weekOfYear", "dayjs_plugin_weekYear", "dayjs_plugin_weekday"], factory);
	else if(typeof exports === 'object')
		exports["antd"] = factory(require("vue"), require("dayjs"), require("dayjs_plugin_advancedFormat"), require("dayjs_plugin_customParseFormat"), require("dayjs_plugin_localeData"), require("dayjs_plugin_weekOfYear"), require("dayjs_plugin_weekYear"), require("dayjs_plugin_weekday"));
	else
		root["antd"] = factory(root["Vue"], root["dayjs"], root["dayjs_plugin_advancedFormat"], root["dayjs_plugin_customParseFormat"], root["dayjs_plugin_localeData"], root["dayjs_plugin_weekOfYear"], root["dayjs_plugin_weekYear"], root["dayjs_plugin_weekday"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__676__, __WEBPACK_EXTERNAL_MODULE__6640__, __WEBPACK_EXTERNAL_MODULE__3402__, __WEBPACK_EXTERNAL_MODULE__9858__, __WEBPACK_EXTERNAL_MODULE__2521__, __WEBPACK_EXTERNAL_MODULE__5100__, __WEBPACK_EXTERNAL_MODULE__5032__, __WEBPACK_EXTERNAL_MODULE__6976__) {
return 